<template>
  <div class="container page">
    <van-nav-bar title="入金カードの記入" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()"/>
      </template>
    </van-nav-bar>
    <div class="main-box">
      <div class="label">銀行カード情報を入力してください</div>
      <van-cell-group>
        <van-field v-model="bank" label="銀行名"  placeholder="銀行を入力してください"/>
        <van-field v-model="shopName" label="支店名" placeholder="支店名を入力してください" />
        <van-field v-model="shopNum" label="支店番号" type="digit" placeholder="支店番号を入力してください" />
        <van-field v-model="tradeType" label="取引種類" placeholder="取引種類（普通）を入力してください" />
        <van-field v-model="bankid" label="銀行口座番号" type="digit" placeholder="銀行口座番号を入力してください" />
        <van-field v-model="banker" label="アカウント名" placeholder="アカウント名を入力してください" />
        <van-field label-width="6.4em" v-model="paypayName" label="paypayユーザー名" placeholder="paypayユーザー名を入力してください" />
        <van-field v-model="paypayId" label="paypay ID" placeholder="paypayIDを入力してください" />
      </van-cell-group>
      <p>尊敬するユーザー、ご資金の安全を保障するには、ご実名のバインディングと暗証番号の設定をお願いします。もし氏名と口座名が一致しない場合、引き出すことができません</p>
    </div>
    <van-button class="bindCard" type="default" @click="bindCard()">カードバインディング確認</van-button>
    <van-popup v-model="showBank" round position="bottom" :style="{ height: '35%' }" >
      <van-picker
          show-toolbar
          :columns="banks"
          @confirm="onConfirm"
          @cancel="onCancel"
      />
    </van-popup>
  </div>
</template>

<script>
export default {
  data() {
    return {
      banks: [],
      showBank:false,
      userInfo:{},
      bankid:"",
      bank:"",
      shopName:"",
      shopNum: "",
      tradeType:"",
      banker:"",
      paypayName: '',
      paypayId: ''
    };
  },
  methods: {
    back(){
      return window.history.back();
    },
    bindCard(){
      if(this.userInfo.bankid){
        this.$toast("銀行カード番号を入力してください。");
        return true;
      }
      if(this.bank === "" || this.bank === null || this.bank === undefined){
        this.$toast.fail("銀行を選択してください。");
        return false;
      }
      if(this.shopName == "" || this.shopNum == "" || this.tradeType == "" || this.banker == "" || this.paypayName == '' || this.paypayId == '') {
        this.$toast.fail("正しい情報を選択してください！");
        return false;
      }
      this.$http({
        method: 'post',
        data:{bankid:this.bankid,bank:this.bank,shop_name:this.shopName,shop_num:this.shopNum,trade_type:this.tradeType,banker:this.tradeType, paypay_name: this.paypayName, paypay_id: this.paypayId},
        url: 'user_set_bank'
      }).then(res=>{
        if(res.code === 200){
          this.$router.push({path:'/Mine'})
          this.$toast(res.msg);
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
    showSelectBanks(){
      this.showBank = true;
    },
    getUserInfo(){
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res=>{
        if(res.code === 200){
          this.userInfo = res.data;
          this.name = res.data.name;
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
    getBankList(){
      this.$http({
        method: 'get',
        url: 'sys_get_banks'
      }).then(res=>{
        if(res.code === 200){
          this.banks = res.data;
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
    onConfirm(value) {
      this.bank = value.text
      this.showBank = false;
    },
    onCancel() {
      this.showBank = false;
    },
    getUserBankInfo(){
      this.$http({
        method: 'get',
        url: 'user_get_bank'
      }).then(res=>{
        if(res.code === 200){
          if(res.data.is_bank){
            this.is_bind = true;
          }else {
            this.is_bind = false;
          }
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    }
  },
  created() {
    if(!localStorage.getItem('token')){
      this.$router.push({path:'/Login'})
    }else {
      this.getUserInfo();
      this.getBankList();
      this.getUserBankInfo();
    }
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";
.van-cell {
  font-size: 32px;
  line-height: 80px;
}
.van-hairline--bottom::after {
  border-bottom-width: 3px;
}
.bankbox{
  padding: 15px;
  color: #000;
  background-color: #fff;
}
.bankbox .title{
  padding: 8px 10px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 28px;
}
.main-box{
  background: #fff;

}

.main-box .label{
  padding: 20px;
  font-size: 35px;
  color: #797878;
}
::v-deep .van-picker__toolbar {
  height: 50px;
}
::v-deep .van-picker__cancel, .van-picker__confirm {
  padding: 0 20px;
  font-size: 20px;
}
::v-deep .van-picker-column {
  font-size: 40px;
}
.main-box p{
  padding: 0 20px;
  font-size: 30px;
  color: #ee0a24;
}
.bindCard {
  margin: 20px 30px 0;
  height: 80px;
  line-height: 1.22667rem;
  border-radius: 50px;
  color: #fff;
  font-size: 30px;
  font-weight: bolder;
  border: none;
  background: linear-gradient(
      90deg,#e6c3a1,#7e5678);
}
</style>
