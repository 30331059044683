<template>
	<div class="page">
		<van-nav-bar title="選妃" class="nav-bar">
			<template #left>
				<van-icon name="arrow-left" color="#fff" @click="back()" />
			</template>
		</van-nav-bar>
		<div class="box">
			<p class="name">名前：{{ this.xuanfeidata.xuanfei_name }}</p>

			<van-image width="98%" fit="contain" height="100%" :src="this.firstPic" />

			<div v-if="ifInfo === true">
				<div v-if="!this.isOrder">
					<van-cell-group class="order-content" >
						<van-field v-model="customer_name" label="名前" placeholder="名前を入力してください"/>
						<van-field v-model="address" label="住所" placeholder="住所を入力してください" />
						<van-field v-model="mobile" label="電話番号" placeholder="携帯電話番号を入力してください" />
						<van-field v-model="line" label="Line" placeholder="Lineを入力してください" />
						<van-field v-model="order_date" label="集合時間"  placeholder="集合時間を選択してください" @click="orderPopup"/>
					</van-cell-group>
					<van-button round="true" @click="orderXuanfei" class="button" color="linear-gradient(to right, #7f5778 , #e5c2a0)">予約する</van-button>
				</div>
				<div v-else >
					<p v-if="this.isOrdering" style="font-size: 25px;margin-top: 40px">予定をロック中です</p>
				</div>

				<div class="thumb-example">
					<swiper
						class="swiper gallery-thumbs"
						:options="swiperOptionThumbs"
						ref="swiperThumbs"
					>
						<swiper-slide
							class="slide"
							style="width:100px;height:100px;"
							v-for="(photo, index) in xuanfeidata.img_url"
							:key="index"
						>
							<img style="width:100px;height:100px;" :src="photo" alt="" />
						</swiper-slide>
					</swiper>
					<swiper
						class="swiper gallery-top"
						:options="swiperOptionTop"
						ref="swiperTops"
					>
						<swiper-slide class="slide-1" v-for="(photo, index) in xuanfeidata.img_url" :key="index">
							<img :src="photo" style="width:100%;height:100%" alt="" />
						</swiper-slide>
					</swiper>
					
				</div>

			</div>
			
		</div>
		

		<div v-if="ifInfo === false" class="servicelistItem">
			<div class="servicelistItemTop">
				<img class="servicelistItemImage" src="img/mine/suo.png">
				<div class="servicelistItemText">タスクをクリアして資料をアンロック</div>
				<div class="servicelistItemBtn" @click="toServicePage()">
					<div class="servicelistItemBtnText">
						タスクへ
					</div>
				</div>
			</div>
			<div class="servicelistItemBottom">
				<div class="servicelistItemInfoText">
					以下の情報がロックざれ、タスクをクリアするとロックが解除されます
				</div>
			</div>
		</div>

		<div v-if="ifInfo === true" class="box1">
			<div class="tableTitle"><span class="midText">連絡先</span></div>
			<!-- <p class="name2">line号：{{ this.xuanfeidata.line }}</p> -->
			<p class="name2">LINE ID：受付顧客に連絡して連絡先を獲得してください</p>
			<div class="tableTitle"><span class="midText">類別</span></div>

			<p class="name2">{{ this.xuanfeidata.category }}</p>
			<div class="tableTitle"><span class="midText">個人情報</span></div>
			<p class="name2">年齢：{{ this.xuanfeidata.age }}</p>
			<p class="name2">身長：{{ this.xuanfeidata.height }}</p>
			<p class="name2">体重：{{ this.xuanfeidata.weight }}</p>
			<p class="name2">バスト：{{ this.xuanfeidata.chest }}</p>
			<div class="tableTitle"><span class="midText">サービス内容</span></div>
			<p class="name2">{{ this.xuanfeidata.service_content }}</p>
			<div class="tableTitle"><span class="midText">個人説明</span></div>
			<p class="name2">{{ this.xuanfeidata.remark }}</p>
		</div>
		<van-popup v-model="showYuyue" round position="bottom" :style="{ height: '35%' }" >
			<van-datetime-picker
				v-model="currentDate"
				type="datetime"
				title="予約日を選択してください"
				confirm-button-text="確認する"
				cancel-button-text="キャンセル"
				:min-date="minDate"
				@confirm="onConfirm"
        @cancel="onCancel"
			/>
		</van-popup>
	</div>
</template>

<script>
export default {
	mounted() {
  },
	data() {
		return {
			xuanfeidata: [],
			ifInfo:[],
			showYuyue: false,
			currentDate: new Date(),
			minDate:new Date(),
			customer_name: '',
			address: '',
			order_date:'',
			isOrder: false,
			mobile:'',
			line: '',
			firstPic: '',
			swiperOptionTop: {
				zoom: true,
				loop: true,
				loopedSlides: 5, // looped slides should be the same
				spaceBetween: 10,
				observer: true, //修改swiper自己或子元素时，自动初始化swiper
				observeParents: true, //修改swiper的父元素时，自动初始化swiper
			},
			swiperOptionThumbs: {
				loop: true,
				loopedSlides: 5, // looped slides should be the same
				spaceBetween: 10,
				centeredSlides: true,
				slidesPerView: 'auto',
				touchRatio: 0.2,
				slideToClickedSlide: true,
			},
			isOrdering: false
		};
	},
	methods: {
		// formatter(type, val) {
		// 	console.log(type, val);
		// },
		onConfirm(value) {
			console.log('value',this.gmtToYMD(value));
			this.order_date = this.gmtToYMD(value);
			
			this.showYuyue = false;
		},
		orderXuanfei() {
			if(!this.customer_name || !this.address || !this.order_date || !this.mobile || !this.line) {
				this.$toast('正しい予約情報を入力してください');
				return ;
			}
			this.$http({
					method: 'post',
					url: 'xuanfeiOrder',
					data: { xuanfei_id: this.$route.query.id, customer_name: this.customer_name,address:this.address, order_date: this.order_date, mobile: this.mobile, line: this.line}
				}).then(res => {
					this.$toast(res.msg);
					this.$router.go(0);
				});
		},
		onCancel() {
      this.showYuyue = false;
    },
		gmtToYMD(gmtDateStr) {
				// 创建一个 Date 对象
				const date = new Date(gmtDateStr);

				// 获取年份、月份和日期
				const year = date.getUTCFullYear();
				const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // 月份从 0 开始
				const day = String(date.getUTCDate()).padStart(2, '0'); // 获取 UTC 日期

				const hours = String(date.getHours()).padStart(2, '0');
				const minutes = String(date.getMinutes()).padStart(2, '0');	

				// 返回格式化的日期
				return `${year}-${month}-${day} ${hours}:${minutes}`;
		},
		back() {
			return window.history.back();
		},
		toServicePage(){
			this.$router.push({path:'/GameRecord'});
		},
		getxuanfeidata() {
			this.$http({
				method: 'get',
				url: 'xuanfeidata',
				data: { id: this.$route.query.id }
			}).then(res => {
				this.xuanfeidata = res.data;
				this.firstPic = res.data.img_url[0];
				this.ifInfo = res.data.ifInfo;

				this.$nextTick(() => {
						const swiperTop = this.$refs.swiperTops.swiper
						const swiperThumbs = this.$refs.swiperThumbs.swiper
						swiperTop.controller.control = swiperThumbs
						swiperThumbs.controller.control = swiperTop
				})
			});
		},
		checkOrder() {
			this.$http({
				method: 'get',
				url: 'checkOrder',
				data: { xuanfei_id: this.$route.query.id }
			}).then(res => {
				if (res.code == 1000) {
					console.log(res.data);
					if (res.data.is_current == true) {
						this.isOrdering = true;
					}
					this.isOrder = true;
				}
			});
		},
		orderPopup() {
			this.showYuyue = true;
		},
	},
	created() {
		this.getxuanfeidata();
		this.checkOrder();
	}
};
</script>

<style>
.container {
	display: inline-block;
}
.box {
	width: 95%;
	margin: 0 auto;
	text-align: center;
	padding-bottom:2.25rem;

	.order-content {
		margin-top: 20px;
	}
	.van-cell{
		padding: 15px 20px;
		font-size: 35px;
		color: #797878;
	}
}

.box1 {
	width: 90%;
	margin: 0 auto;
	padding-bottom: 6.25rem;
}
.name1 {
	font-size: 1.125rem;
	margin: 0 auto;
	text-align: center;
}
.name {
	font-size: 1.125rem;
}
.name2 {
	color: #615b5b;
	font-size: 0.9rem;
}
.title {
	font-size: 0.625rem;
}
.button {
	width: 10rem;
	height: 2.5rem;
	font-size: 0.9375rem;
	margin-top: 0.625rem;
}


.tableTitle {
    position: relative;
    margin: 40px auto;
    width: 100%;
    height: 1.5px;
    background-color: #000000;
    text-align: center;
    font-size: 26px;
    color: #000000;
  }
 .midText {
    position: absolute;
    left: 50%;
    background-color: #f2f3f5;
    padding: 0 15px;
    transform: translateX(-50%) translateY(-50%);
  }
.servicelistItem{
  display: flex;
  flex-direction: column;
  height: 200px;
  padding: 30px 30px;
  margin: 30px 20px;
  border-radius: 20px;
  justify-content: space-between;
  background: #fff;
}
.servicelistItem .servicelistItemTop{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 100px;
}
.servicelistItem .servicelistItemTop .servicelistItemImage{
  width: 80px;
  height: 80px;
  border-radius: 30%;
}
.servicelistItem .servicelistItemTop .servicelistItemText{
  margin-left: 50px;
  font-size: 43px;
  font-weight: 700;
  color: #000;
  flex: 1;
}
.servicelistItem .servicelistItemTop .servicelistItemBtn{
  display: flex;
  width: 150px;
  height: 55px;
  border-radius: 30px;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
      270deg,#e6c3a1,#7e5678);
}
.servicelistItem .servicelistItemTop .servicelistItemBtn .servicelistItemBtnText{
  color: #fff;
  font-size: 25px;
}
.servicelistItem .servicelistItemBottom{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  background: #f2f2f5;
  border-radius: 10px;
  color: #979799;
}
.servicelistItem .servicelistItemBottom .servicelistItemInfoText{
  font-size: 30px;
}

.thumb-example {
  width: 864px;
  margin-top: 20px;
  background: #fff;
}
.swiper-slide {
  background-size: cover;
  background-position: center;
}
.gallery-top {
  height: 100% !important;
  /* height: 600px; */
  width: 100%;
}
.gallery-thumbs {
  height: 20% !important;
  box-sizing: border-box;
  padding: 10px 0px;
  width: 864px;
  margin-left: 2px;
  .swiper-button-next {
    right: 0px;
  }
  .swiper-button-prev {
    left: 0px;
  }
  .swiper-button-next,
  .swiper-button-prev {
    background: #fff;
    width: 45px;
    text-align: center;
    height: 101px;
    top: 26%;
    div {
      margin-top: 30px;
      background: rgb(207, 205, 205);
      height: 45px;
      border-radius: 50%;
      img {
        margin: 7px 0 0 2px;
        width: 30px;
      }
    }
  }
  .swiper-button-next:hover div {
    background: rgb(189, 186, 186);
  }
  .swiper-button-prev:hover div {
    background: rgb(189, 186, 186);
  }
}
.gallery-thumbs .swiper-slide {
  width: 20%;
  height: 80px;
  /* opacity: 0.4; */
}
.gallery-thumbs .swiper-slide-active {
  /* border: 1px solid red; */
}
</style>
