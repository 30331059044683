<template>
  <div class="home-container">
    <van-nav-bar title="ホームページ" class="nav-bar"> </van-nav-bar>
    <div class="linear-bg"></div>
    <div class="home-scroll">
      <div class="banner">
        <swiper class="banner_swiper" :options="bannerSwiperOption">
          <swiper-slide v-for="(v, key) in banners" :key="key">
            <van-image class="banner_img" round :src="v.url">
              <template v-slot:loading>
                <van-loading type="circular" />
              </template>
            </van-image>
          </swiper-slide>
        </swiper>
      </div>
      <div class="notice-bar">
        <van-notice-bar
          class="notice-swipe"
          left-icon="bullhorn-o"
          background="#ffffff"
          color="#7e5678"
          :text="this.notice"
        />
        <div class="linear-gradient"></div>
      </div>
      <div class="hot-game">
        <div class="hot-title-div">
          <div>
            <span>ハイエンドゾーン</span>
            <i>究極の贅沢体験をお楽しみください </i>
          </div>
          <!-- <div @click="gotoMenu('/Game')">
            <span>もっとへ</span>
            <van-icon name="arrow" color="#979799" />
          </div> -->
        </div>
        <div class="hot-items-div">
          <van-grid :border="false" :gutter="8" style="overflow-y: auto;width:100%;flex-wrap:nowrap;">
            <van-grid-item
              v-for="(v, key) in gameitem"
              :key="key"
            >
            <div class="item-grid" @click="toLottery(v[0].xuanfei_name, v[0].id, v[0].type)">
              <van-image class="game_item_img" :src="v[0].img_url"></van-image>
              <span><i>飛行機</i></span>
              <div>{{ v[0].xuanfei_name }}</div>
            </div>

            <div v-if="v[1]" class="item-grid" @click="toLottery(v[1].xuanfei_name, v[1].id, v[0].type)">
              <van-image class="game_item_img" :src="v[1].img_url"></van-image>
              <span><i>飛行機</i></span>
              <div>{{ v[1].xuanfei_name }}</div>
            </div>
            <div v-else class="item-grid-empty">
              
            </div>
            
              <!-- <van-image class="game_item_img" :src="v.ico">
                <template v-slot:loading>
                  <van-loading type="circular" />
                </template>
              </van-image>
              <span>{{ v.name }}</span> -->
            </van-grid-item>
          </van-grid>
        </div>
      </div>
      <van-pull-refresh
        v-model="isLoading"
        loading-text="読み込み中"
        loosing-text="放してリフレッシュ"
        @refresh="onRefresh"
      >
        <div class="hot-recommend">
          <div class="hot-title-div">
            <div>
              <span>人気のおすすめ</span>
            </div>
            <!-- <div>
              <span @click="gotoMenu('/Video')">もっとへ</span>
              <van-icon name="arrow" color="#979799" />
            </div> -->
          </div>
          <!-- <div class="movie_list_0">
            <swiper class="movie_swiper" :options="movielistSwiperOption">
              <swiper-slide v-for="(v, key) in movielist_0" :key="key">
                <van-image
                  class="movie_cover"
                  @click="toPlayVideo(v.id)"
                  round
                  :src="v.cover"
                >
                  <template v-slot:loading>
                    <van-loading type="circular" />
                  </template>
                </van-image>
                <img class="hot" v-if="key === 0" src="/img/home/no1.png" />
                <img class="hot" v-if="key === 1" src="/img/home/no2.png" />
                <img class="hot" v-if="key === 2" src="/img/home/no3.png" />
                <div class="movie-list-item-bottom">
                  <div class="movie-time-div">
                    <span>{{ v.title }}</span>
                    <div class="van-count-down">{{ v.time }}</div>
                  </div>
                </div>
              </swiper-slide>
            </swiper>
          </div> -->
          <!-- <div class="hot-title-div">
            <div>
              <span>ホット推奨</span>
            </div>
            <div @click="gotoMenu('/Video')">
              <span>もっとへ</span>
              <van-icon name="arrow" size="25" color="#979799" />
            </div>
          </div> -->
          <div class="movie_list_1">
            <div
              class="movie-list-item"
              v-for="(v, key) in lottery_list"
              :key="key"
              @click="toPlayVideo(v.id)"
            >

              <div class="horizontal-item">
                <div class="img-box">
                  <van-image class="cover_img" round :src="v.img_url">
                    <template v-slot:loading>
                      <van-loading type="circular" />
                    </template>
                  </van-image>
                  <span><i>貴殿</i></span>
                </div>
                <div style="width: calc(100% - 140px);">
                  <div class="top">
                    <div>
                      <van-image style="width:13px" :src="vipImg"></van-image>
                      <span>認証</span>
                    </div>
                    <div>
                      <van-image style="width:13px" :src="vipImg"></van-image>
                      <span>ビデオ認証</span>
                    </div>
                    <div>{{ v.xuanfei_name }}</div>
                  </div>
                  <div class="tags">
                    <van-image class="man-tag" width="39"  :src="jpImg"></van-image>
                    <van-image class="man-tag" width="39"  :src="fyImg"></van-image>
                    <van-image class="man-tag" width="51"  :src="byImg"></van-image>
                    <van-image class="man-tag" width="85"  :src="rwImg"></van-image>
                  </div>  
                  <div class="score-range">
                    <span>課金区間：</span>
                    <van-rate
                    v-model="v.score"
                    :size="18"
                    color="#ffd21e"
                    void-icon="star"
                    void-color="#eee"
                    />
                  </div>
                  <div class="p-desc">
                    身長: {{ v.height }} 胸囲:{{ v.chest }} 重さ:{{ v.weight }} 都市に奉仕する： {{ v.area}} 
                  </div>
                  <div class="order-him"><van-image width="100%"  :src="ytImg"></van-image></div>
                </div>
              </div>
              <!-- <van-image class="cover_img" round :src="v.cover">
                <template v-slot:loading>
                  <van-loading type="circular" />
                </template>
              </van-image>
              <div class="movie-list-item-bottom">
                <div class="movie-time-div">
                  <span>{{ v.title }}</span>
                  <span>遊ぶ:{{ v.count }}</span>
                </div>
              </div> -->
            </div>
            <!-- <div class="hot-recommend-more" @click="gotoMenu('/Video')">
              もっとへ
            </div> -->
          </div>
        </div>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>

import vipImg from "@/assets/img/vip.png"
import byImg from "@/assets/img/by.png"
import rwImg from "@/assets/img/rw.png"
import ytImg from "@/assets/img/yueta.png"
import jpImg from "@/assets/img/jp.png"
import fyImg from "@/assets/img/fy.png"

export default {
  data() {
    return {
      notice: "获取中......",
      banners: [{}],
      basicData: [],
      gameitem: [],
      movielist_0: [{}, {}, {}, {}],
      movielist_1: [{}, {}, {}, {}, {}, {}, {}, {}],
      isLoading: false,
      movielistSwiperOption: {
        slidesPerView: "auto",
        spaceBetween: 0,
        slidesPerGroup: 1,
      },
      bannerSwiperOption: {
        effect: "coverflow",
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: "auto",
        speed: 800,
        autoplay: true,
        coverflowEffect: {
          rotate: 50,
          stretch: 10,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        },
      },
      vipImg: vipImg,
      byImg: byImg,
      rwImg: rwImg,
      ytImg: ytImg,
      jpImg:jpImg,
      fyImg: fyImg,
      lottery_list: []
    };
  },
  methods: {
    gotoMenu(router) {
      this.$router.replace(router);
    },
    toLottery(key, id, type) {
      if (!localStorage.getItem("token")) {
        this.$router.push({ path: "/Login" });
      } else {
        if (type == 1) {
          this.$router.push({ path: "/Profile?id=" + id });
        }else {
          this.$router.push({ path: "/Lottery?key=" + key + "&id=" + id });
        }
        
      }
    },
    toPlayVideo(id) {
      if (!localStorage.getItem("token")) {
        this.$router.push({ path: "/Login" });
      } else {
        this.$router.push({ path: "/Profile?id=" + id });
      }
    },
    onRefresh() {
      setTimeout(() => {
        this.getLotteryList();
        this.isLoading = false;
        this.$toast("更新に成功しました");
      }, 500);
    },
    getBasicConfig() {
      this.$http({
        method: "get",
        url: "sys_config",
      }).then((res) => {
        this.basicData = res.data;
        this.getNotice(this.basicData); //获取公告
        this.getBanner(this.basicData); //获取banner
        // this.getMovieList_0(this.basicData); //获取首页视频0
        // this.getMovieList_1(this.basicData); //获取首页视频1
      });
    },
    getNotice(data) {
      this.notice = data.notice;
    },
    getLotteryList(){
      this.$http({
        method: 'get',
        url: 'get_lottery_list'
      }).then(res=>{
        if(res.code === 200){
          this.lottery_list = res.data;
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
    getGameItem() {
      this.$http({
        method: "get",
        url: "lottery_hot",
      }).then((res) => {
        var tempArr = [];
        var count = 2;
        for(var i in res.data) {
          var index = parseInt(i/count);
          if (tempArr.length <= index) {
            tempArr.push([]);
          }
          tempArr[index].push(res.data[i]);
        }
        this.gameitem = tempArr;
      });
    },
    // getMovieList_0(data) {
    //   this.movielist_0 = data.movielist_0;
    // },
    // getMovieList_1(data) {
    //   this.movielist_1 = data.movielist_1;
    // },
    getBanner(data) {
      this.banners = data.banners;
    },
  },
  mounted() {},
  created() {
    this.getGameItem(); //获取首页游戏列表
    this.getBasicConfig();
    this.getLotteryList();
  },
};
</script>

<style lang="less" scoped>
@notice-bar-size: 30px;
@movie-list-item-bottom-size: 25px;
.nav-bar {
  background: linear-gradient(30deg, #7d76ef, #d63f8c);
}
.linear-bg {
  height: 200px;
  background: linear-gradient(270deg, #e6c3a1, #7e5678);
}
.home-container {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  background-color: #ffffff;
}
.linear-gradient {
  width: 100%;
  height: 2px;
  background: linear-gradient(
    to right,
    rgba(126, 86, 120, 0),
    rgb(230, 195, 161),
    rgba(126, 86, 120, 0)
  );
}
::v-deep .van-notice-bar__left-icon,
.van-notice-bar__right-icon {
  min-width: 40px;
}
.notice-swipe {
  width: calc(100% - 50px);
  height: 85px;
  font-size: @notice-bar-size;
}
::v-deep .van-icon-bullhorn-o::before {
  transform: scale(2.5);
}
.banner {
  width: 100%;
  margin-top: -23%;
}
.banner_swiper {
  height: 100%;
  width: 100%;

  .swiper-slide {
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 620px;
    height: 300px;
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    background-color: #ffffff;
    background-position: center;
    background-size: cover;
    color: #ffffff;
  }
}
::v-deep .swiper-container-3d .swiper-slide-shadow-left {
  background-image: linear-gradient(
    to left,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0)
  );
}
::v-deep .swiper-container-3d .swiper-slide-shadow-right {
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0)
  );
}
.banner_img {
  border-radius: 10px;
  width: 100%;
  height: 100%;
}
.hot-game {
  width: 100%;
  height: 100%;
}
.hot-title-div {
  width: calc(100% - 50px);
  margin: 0 auto;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hot-title-div div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.hot-title-div > div:nth-child(2) span {
  font-size: 20px;
  color: #979799;
}

.hot-title-div > div:first-child span {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 28px;
  color: #000;
}
.hot-title-div> div:first-child i {
  background: #3f3a5b;
  padding: 0.533vw;
  color: #ebcaaf;
  font-size: 2.933vw;
  border-radius: 1.067vw 0;
}
.hot-title-div > div:nth-child(2) span {
  font-size: 25px;
  color: #979799;
}
.hot-title-div > div:first-child span:before {
  content: "";
  display: block;
  width: 5px;
  height: 30px;
  background-color: #7e5678;
  border-radius: 1px;
  margin-right: 5px;
}
.hot-game .hot-items-div {
  margin-top: -3px;
}
.hot-game .hot-items-div span {
  margin-top: 10px;
  font-size: 28px;
  color: #000;
}

.hot-game .hot-items-div .item-grid {
  position: relative;
  overflow: hidden;
  border-radius: 1.067vw;
  margin-bottom:2px;
}

.item-grid-empty {
  width: 191px;
  height: 191px;
}

.hot-items-div .game_item_img {
  width: 191px;
  height: 191px;
  overflow: hidden;
  border-radius: 8px;
}
.hot-items-div .item-grid > span {
  position: absolute;
  z-index: 9;
  background-color: rgb(252,205,203);
  color: rgb(179,137,155);
  transform: rotate(45deg);
  width: 19vw;
  height: 19vw;
  font-size: 2.5vw;
  top: -11vw;
  right: -11vw;
}
.hot-items-div .item-grid > span > i {
  position: absolute;
  bottom: 0.667vw;
  left: 45%;
  transform: translateX(-50%);
  font-weight: 700;
}
.hot-items-div .item-grid div:last-child {
  position: absolute;
  bottom: 10px;
  text-align: center;
  color: rgb(255, 255, 255);
  width: 100%;
  font-size: 12px;
}
.hot-recommend {
  width: 100%;
  flex: 1;
  background-color: #fff;
}
.movie_swiper {
  .swiper-slide {
    width: 80%;
  }
}
.movie_list_0 {
  width: calc(100% - 50px);
  margin: 0 auto;
}
.movie_cover {
  border-radius: 10px;
  width: 550px;
  height: 330px;
}
.movie_list_0 .movie-list-item-bottom {
  position: relative;
  width: 550px;
  bottom: 43px;
}
.movie_list_0 .movie-list-item-bottom .movie-time-div {
  background-color: rgba(0, 0, 0, 0.4);
}
.movie_list_0 .movie-list-item-bottom > div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.movie_list_0 .movie-list-item-bottom .movie-time-div .van-count-down {
  font-size: 28px;
  color: #fff;
}
.movie_list_0 .movie-time-div {
  color: #fff;
  border-radius: 0 0 10px 10px;
}
.movie_list_0 .movie_swiper .hot {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 80px;
}
.movie_list_0 span {
  font-size: 30px;
}
.movie_list_1 {
  box-sizing:border-box;
  // display: flex;
  // width: calc(100% - 50px);
  // margin: 0 auto;
  // align-items: flex-start;
  // justify-content: flex-start;
  // flex-wrap: wrap;
}
.movie_list_1 .movie-list-item .cover_img {
  width: 232px;
  height: 232px;
  overflow: hidden;
  border-radius: 15px;
}
.home-scroll {
  padding-bottom: 110px;
}
.movie_list_1 .movie-list-item {
  margin: 0 10px;
}

.movie_list_1 .horizontal-item {
  display: flex;
  border-bottom: 1px solid #eee;
  padding: 2.667vw 0 4vw;
}

.movie_list_1 .horizontal-item .img-box {
  position: relative;
  overflow: hidden;
  border-radius: 1.067vw;
  margin-right: 2.667vw;
}

.movie_list_1 .horizontal-item .img-box span {
  position: absolute;
    z-index: 1;
    background-color: rgb(252,205,203);
    color: rgb(179,137,155);
    transform: rotate(45deg);
    width: 20vw;
    height: 20vw;
    top: -11vw;
    right: -11vw;
}

.movie_list_1 .horizontal-item .img-box span i {
  position: absolute;
    bottom: 0.667vw;
    left: 50%;
    transform: translateX(-50%);
    font-weight: 700;
    font-size: 4vw;
}
.movie_list_1 .horizontal-item .p-desc {
  color: rgb(153, 153, 153);
  font-size: 14px;
  margin: 5px 0px 10px;
}

.movie_list_1 .horizontal-item .score-range {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.movie_list_1 .horizontal-item .score-range span {
  font-size: 14px;
  color: rgb(102, 102, 102);
}

.movie_list_1 .horizontal-item .tags {
  display: flex;
  margin: 0.667vw 0 10px;
  flex-wrap: wrap;
}

.movie_list_1 .horizontal-item .tags .man-tag {
  padding: 0.667vw;
  font-size: 3.333vw;
  margin: 0 0.667vw 0.667vw 0;
  border-radius: 1.067vw;
  line-height: 4vw;
}

.movie_list_1 .horizontal-item .top {
  display: flex;
  margin-bottom: 10px;
}

.movie_list_1 .horizontal-item .top > div:first-child, .movie_list_1 .horizontal-item .top > div:nth-child(2) {
  background: linear-gradient(180deg,rgb(254,192,195), rgb(252,215,212));
  color: rgb(174,133,165);
  font-weight: 600;
  font-size: 3vw;
  padding: 0.4vw;
  margin-right: 1.333vw;
  border-radius: 7px;
}

.movie_list_1 .horizontal-item .top > div:last-child {
  width: calc(100% - 250px);
  overflow: hidden;
  height: 37px;
  font-weight: 300;
}

.movie_list_1 .movie-list-item-bottom {
  position: relative;
  width: 335px;
  bottom: 42px;
}
.movie_list_1 .movie-list-item:nth-child(odd) {
  margin-right: 25px;
}
.movie_list_1 .movie-list-item-bottom .movie-time-div {
  background-color: rgba(0, 0, 0, 0.4);
}
.movie_list_1 .movie-list-item-bottom > div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.movie_list_1 .movie-list-item-bottom .movie-time-div .van-count-down {
  color: #fff;
}
.movie_list_1 .movie-time-div {
  color: #fff;
  border-radius: 0 0 10px 10px;
  height: 35px;
}
.movie_list_1 .movie_swiper .hot {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 5px;
}

.movie_list_1 .movie-list-item .movie-time-div span:first-child {
  overflow: hidden;
  white-space: nowrap;
  width: 180px;
  padding-left: 8px;
  font-size: 25px;
}
.movie_list_1 .movie-list-item .movie-time-div span:last-child {
  overflow: hidden;
  white-space: nowrap;
  width: 0px;
  padding-right: 110px;
  font-size: 22px;
}
.movie_list_0 .movie-time-div span:first-child {
  overflow: hidden;
  white-space: nowrap;
  width: 350px;
  padding-left: 10px;
  font-size: 25px;
}

.hot-recommend-more {
  width: 100%;
  padding-bottom: 20px;
  text-align: center;
  color: #979799;
  font-size: 30px;
}

::v-deep .hot-items-div .game_item_img .van-image__img {
  border-radius: 20px;
}

::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
  color: #000000;
  font-size: 35px;
}
</style>
