<template>
  <div class="container page">
    <van-nav-bar title="タスク記録" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()"/>
      </template>
    </van-nav-bar>
    <!-- <div class="servicelistItem">
        <div class="servicelistItemTop">
          <img class="servicelistItemImage" src="img/mine/kefu.png">
          <div class="servicelistItemText">完成任务解锁选妃</div>
          <div class="servicelistItemBtn" @click="toServicePage()">
            <div class="servicelistItemBtnText">
              联系客服
            </div>
          </div>
        </div>
        <div class="servicelistItemBottom">
        <div class="servicelistItemInfoText">
          全天7 * 24小时竭诚为您服务
        </div>
      </div>
    </div> -->

    <div class="main">
      <van-pull-refresh v-model="isLoading" loading-text="読み込み中" loosing-text="放してリフレッシュ" @refresh="onRefresh">
        <van-empty v-if="list.length === 0" description="データが空です！"/>
        <div v-else class="item_list" v-for="(v,key) in list" :key="key">
          <div class="lottery_info">
            <van-image class="cover" :src="v.ico">
              <template v-slot:loading>
                <van-loading type="spinner"/>
              </template>
            </van-image>
            <span class="period-number">{{ v.expect }}</span>
            <span class="period-number">{{ v.name }}</span>
          </div>
          <div class="recent">
            <div class="kuaisan-ball left">
              <van-image class="res-img"
                         :src=" v.status === 0 ? 'img/lottery/open_num.gif' : 'img/lottery/shaizi/' + v.opencode[0] + '.png' ">
                <template v-slot:loading>
                  <van-loading type="spinner"/>
                </template>
              </van-image>
              <van-image class="res-img"
                         :src=" v.status === 0 ? 'img/lottery/open_num.gif' : 'img/lottery/shaizi/' + v.opencode[1] + '.png' ">
                <template v-slot:loading>
                  <van-loading type="spinner"/>
                </template>
              </van-image>
              <van-image class="res-img"
                         :src=" v.status === 0 ? 'img/lottery/open_num.gif' : 'img/lottery/shaizi/' + v.opencode[2] + '.png' ">
                <template v-slot:loading>
                  <van-loading type="spinner"/>
                </template>
              </van-image>
              <span
                  class="res-des middle">{{ v.status === 0 ? 0 : v.opencode[0] + v.opencode[1] + v.opencode[2] }}</span>
              <span
                  class="res-des middle">{{
                  v.status === 0 ? 0 : (v.opencode[0] + v.opencode[1] + v.opencode[2]) >= 11 && (v.opencode[0] + v.opencode[1] + v.opencode[2]) &lt;= 18 ? "ビッグ" : "スモール"
                }}</span>
              <span
                  class="res-des middle">{{
                  v.status === 0 ? 0 : (v.opencode[0] + v.opencode[1] + v.opencode[2]) % 2 === 0 ? "ダブル" : "シングル"
                }}</span>
            </div>
          </div>
          <!--          <div class="topInfo">-->
          <!--            <span v-if="v.is_win === 1" >勝つ+{{ v.money }}</span>-->
          <!--            <span v-else style="color: #07c160">負ける-{{ v.money }}</span>-->
          <!--&lt;!&ndash;            <span>ベット額：{{ v.money }}</span>&ndash;&gt;-->
          <!--          </div>-->
          <div class="topInfo">
<!--            <span v-if="v.is_win === 1" style="color: #07c160">{{ v.win_text }}</span>-->
<!--            <span v-else>{{ v.win_text }}</span>-->
            <span>ベット額：{{ v.money }}</span>
          </div>
          <!-- <div class="topInfo">
            <span>任务类型：</span>
            <span>{{v.type}}</span>
          </div> -->
          <div class="time">
            <span>注文時間：{{ v.create_time }}</span>
            <span v-if="v.is_win === 1" style="color: #07c160; float:right;margin-right: 5%;font-size: 15px;">勝つ+{{ v.profit }}</span>
            <span v-else style="float:right;color: #ff253f;margin-right: 5%;font-size: 15px;">負ける-{{ v.profit }}</span>
          </div>
          <div class="time">
            <span>決済時間：{{ v.update_time }}</span>
          </div>
        </div>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      list: [],
    };
  },
  methods: {
    back() {
      return window.history.back();
    },
    toServicePage() {
      this.$router.push("ServicePage");
    },
    onRefresh() {
      setTimeout(() => {
        this.$toast('更新に成功しました');
        this.isLoading = false;
      }, 500);
    },
    getUserGameList() {
      this.$http({
        method: 'get',
        url: 'user_get_game_list'
      }).then(res => {
        if (res.code === 200) {
          console.log(res.data)
          this.list = res.data;
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      })
    }

  },
  created() {
    if (!localStorage.getItem('token')) {
      this.$router.push({path: '/Login'})
    } else {
      this.getUserGameList();
    }
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";

::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
  color: #000000;
  font-size: 35px;
}

::v-deep .van-loading__text {
  color: #000000;
  font-size: 35px;
}

.container .main {
  position: relative;
  overflow: auto;
  background-color: #f2f2f5;
  height: 100%;
  padding: 0 10px;
}

.item_list {
  padding: 15px 15px;
  margin: 30px 10px;
  background: #fff;
  border-radius: 10px;
  line-height: 60px;
}

.item_list .topInfo span {
  flex: 1;
  font-size: 35px;
  font-weight: 700;
  color: #ff253f;
}

.item_list .time span {
  flex: 1;
  font-size: 25px;
  font-weight: 500;
  color: #000;
}

.item_list .topInfo span:last-child {
  float: right;
}

.item_list .desc span {
  font-size: 25px;
  font-weight: 700;
  color: #9b9b9b;
}

.item_list .cover {
  width: 60px;
  height: 60px;
  -o-object-fit: cover;
  object-fit: cover;
}

.item_list .period-number {
  margin-left: 50px;
  margin-right: 10px;
  height: 50px;
  line-height: 60px;
  font-size: 35px;
  font-weight: 700;
  color: #000;
}

.item_list .lottery_info {
  display: flex;
}

.recent {
  display: flex;
  align-items: center;
  height: 100px;
}

.kuaisan-ball .left {
  justify-content: flex-start;
}

.kuaisan-ball {
  flex: 1;
  display: flex;
  align-items: center;
}

.kuaisan-ball .res-img {
  width: 70px;
  height: 70px;
  margin-right: 30px;
}

.kuaisan-ball .res-des {
  font-weight: 700;
  text-align: center;
  color: #000;
}

.kuaisan-ball .res-des.middle {
  width: 17%;
  font-size: 28px;
}

.servicelistItem {
  display: flex;
  flex-direction: column;
  height: 200px;
  padding: 30px 30px;
  margin: 30px 20px;
  border-radius: 20px;
  justify-content: space-between;
  background: #fff;
}

.servicelistItem .servicelistItemTop {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 100px;
}

.servicelistItem .servicelistItemTop .servicelistItemImage {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.servicelistItem .servicelistItemTop .servicelistItemText {
  margin-left: 50px;
  font-size: 43px;
  font-weight: 700;
  color: #000;
  flex: 1;
}

.servicelistItem .servicelistItemTop .servicelistItemBtn {
  display: flex;
  width: 150px;
  height: 55px;
  border-radius: 30px;
  align-items: center;
  justify-content: center;
  background: linear-gradient(270deg, #e6c3a1, #7e5678);
}

.servicelistItem .servicelistItemTop .servicelistItemBtn .servicelistItemBtnText {
  color: #fff;
  font-size: 25px;
}

.servicelistItem .servicelistItemBottom {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  background: #f2f2f5;
  border-radius: 10px;
  color: #979799;
}

.servicelistItem .servicelistItemBottom .servicelistItemInfoText {
  font-size: 30px;
}
</style>
