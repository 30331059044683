<template>
  <div>
    <div class="thumb-example">
      <!-- swiper2 Thumbs -->
      <swiper
        class="swiper gallery-thumbs"
        :options="swiperOptionThumbs"
        ref="swiperThumbs"
      >
        <swiper-slide
          class="slide"
          style="width:100px;height:100px;"
          v-for="(item, index) in bigImg"
          :key="index"
        >
          <img style="width:100px;height:100px;" :src="item" alt="" />
        </swiper-slide>
      </swiper>
      <!-- swiper1 -->
      <swiper
        class="swiper gallery-top"
        :options="swiperOptionTop"
        ref="swiperTop"
      >
        <swiper-slide class="slide-1" v-for="(item,index) in bigImg" :key="index">
          <img :src="item" style="width:100%" alt="" />
        </swiper-slide>
        <!-- <div
          class="swiper-button-next swiper-button-white"
          slot="button-next"
        ></div>
        <div
          class="swiper-button-prev swiper-button-white"
          slot="button-prev"
        ></div> -->
      </swiper>
      
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    // 实现swiper双向控制
    this.$nextTick(() => {
      const swiperTop = this.$refs.swiperTop.swiper
      const swiperThumbs = this.$refs.swiperThumbs.swiper
      swiperTop.controller.control = swiperThumbs
      swiperThumbs.controller.control = swiperTop
    })
  },
  data() {
    return {
      //轮播大图配置
      bigImg: [
        'https://t7.baidu.com/it/u=3165657288,4248157545&fm=193&f=GIF',
         'https://t7.baidu.com/it/u=2942499027,2479446682&fm=193&f=GIF',
         'https://t7.baidu.com/it/u=2610975262,3538281461&fm=193&f=GIF',
          'https://t7.baidu.com/it/u=4138158235,3956816634&fm=193&f=GIF',
      ],
      swiperOptionTop: {
        zoom: true,
        loop: true,
        loopedSlides: 5, // looped slides should be the same
        spaceBetween: 10,
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        // autoplay: {  //自动轮播
        //   delay: 2000,
        //   disableOnInteraction: false
        // },
        // navigation: {
        //   nextEl: '.swiper-button-next',
        //   prevEl: '.swiper-button-prev'
        // }
      },
      swiperOptionThumbs: {
        loop: true,
        loopedSlides: 5, // looped slides should be the same
        spaceBetween: 10,
        centeredSlides: true,
        slidesPerView: 'auto',
        touchRatio: 0.2,
        slideToClickedSlide: true,
        // navigation: {
        //   nextEl: '.swiper-button-next',
        //   prevEl: '.swiper-button-prev'
        // }
      }
    }
  },

  methods: {}
}
</script>
<style lang="less" scoped>
h3 {
  margin: 20px 0 0 10px;
}
.thumb-example {
  width: 864px;
  margin-top: 20px;
  // background: #000;
}
.swiper-slide {
  background-size: cover;
  background-position: center;
}
.gallery-top {
  // height: 80% !important;
  height: 600px;
  width: 100%;
}
.gallery-thumbs {
  height: 20% !important;
  box-sizing: border-box;
  padding: 10px 0px;
  width: 864px;
  margin-left: 2px;
  .swiper-button-next {
    right: 0px;
  }
  .swiper-button-prev {
    left: 0px;
  }
  .swiper-button-next,
  .swiper-button-prev {
    background: #fff;
    width: 45px;
    text-align: center;
    height: 101px;
    top: 26%;
    div {
      margin-top: 30px;
      background: rgb(207, 205, 205);
      height: 45px;
      border-radius: 50%;
      img {
        margin: 7px 0 0 2px;
        width: 30px;
      }
    }
  }
  .swiper-button-next:hover div {
    background: rgb(189, 186, 186);
  }
  .swiper-button-prev:hover div {
    background: rgb(189, 186, 186);
  }
}
.gallery-thumbs .swiper-slide {
  width: 20%;
  height: 80px;
  // opacity: 0.4;
}
.gallery-thumbs .swiper-slide-active {
  border: 2px solid red;
}
</style>
