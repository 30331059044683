<template>
  <div class="container page">
    <van-nav-bar title="入金カード情報" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()"/>
      </template>
    </van-nav-bar>
    <div class="wrapper">
      <div class="add-card" @click="toBindCard()" v-if="!is_bind">
        <van-icon name="plus" />
        <span> 銀行カード追加</span>
      </div>
      <!-- <div class="bank" v-else>
          <div class="info">
            <div class="row-content">{{this.bankInfo.bankinfo}}</div>
            <div class="row-content">{{this.userInfo.name}}</div>
            <div class="row-content">{{this.bankInfo.bankid}}</div>
          </div>
      </div> -->
      <div  v-else class="servicelistItem">
        <div class="servicelistItemTop">
          <img class="servicelistItemImage" src="img/mine/suo.png">
          <div class="servicelistItemText">名前:{{this.userInfo.name}}</div>
          <!-- <div class="servicelistItemBtn" @click="toServicePage()">
            <div class="servicelistItemBtnText">
              联系客服
            </div>
          </div> -->
        </div>
        <div class="servicelistItemBottom">
          <div class="servicelistItemInfoText">
            カード所有者：{{this.bankInfo.banker}}
          </div>
        </div>
        <div class="servicelistItemBottom">
          <div class="servicelistItemInfoText">
            カード番号：{{this.bankInfo.bankid}}
          </div>
        </div>
        <div class="servicelistItemBottom">
          <div class="servicelistItemInfoText">
            銀行情報：{{this.bankInfo.bankinfo}}
          </div>
        </div>
        <div class="servicelistItemBottom">
          <div class="servicelistItemInfoText">
            paypay ID：{{this.bankInfo.paypay_id}}
          </div>
        </div>
        <div class="servicelistItemBottom">
          <div class="servicelistItemInfoText">
            paypayユーザー名：{{this.bankInfo.paypay_name}}
          </div>
        </div>
        <div class="servicelistItemBottom">
          <div class="servicelistItemInfoText">
            支店名：{{this.bankInfo.shop_name}}
          </div>
        </div>
        <div class="servicelistItemBottom">
          <div class="servicelistItemInfoText">
            支店番号：{{this.bankInfo.shop_num}}
          </div>
        </div>
        <div class="servicelistItemBottom">
          <div class="servicelistItemInfoText">
            取引タイプ：{{this.bankInfo.trade_type}}
          </div>
        </div>
        
        
      </div>

      
      <div class="tips">ヒント: 変更する必要がある場合は、大手商業銀行にバインドしてください。オンライン カスタマー サービスにお問い合わせください。</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      is_bind:false,
      bankInfo:{},
      userInfo:{}
    };
  },
  methods: {
    back(){
      return window.history.back();
    },
    getUserBankInfo(){
      this.$http({
        method: 'get',
        url: 'user_get_bank'
      }).then(res=>{
        if(res.code === 200){
          if(res.data.is_bank){
            this.is_bind = true;
            this.bankInfo = res.data.info;
          }else {
            this.is_bind = false;
          }
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
    getUserInfo(){
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res=>{
        if(res.code === 200){
          this.userInfo = res.data;
          this.name = res.data.name;
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
    toBindCard() {
      // if (!this.userInfo.name) {
      //   this.$router.push("Setname");
      //   this.$toast("銀行カードをバインドする前に名前を設定してください！");
      //   return true;
      // }else if(!this.userInfo.paypassword){
      //   this.$router.push("SetPayPassword");
      //   this.$toast("銀行カードをバインドする前に、出金パスワードを設定してください！");
      //   return true;
      // } else {
        this.$router.push({path:'/BindCard'})
      // }
    }
  },
  created() {
    if(!localStorage.getItem('token')){
      this.$router.push({path:'/Login'})
    }else {
      this.getUserInfo();
      this.getUserBankInfo();
    }
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";
.van-cell {
  font-size: 35px;
  line-height: 80px;
}

.container p{
  padding: 0 15px;
  margin-top: 15px;
  font-size: 30px;
  color: #dc2037;
}
.manage-card .wrapper{
  height: calc(100% - 10px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.wrapper .add-card{
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  background-color: #fff;
  height: 250px;
}
.wrapper .add-card span{
  margin-left: 10px;
  font-size: 30px;
}
.wrapper .tips {
  margin: 15px 15px;
  font-size: 25px;
  color: #979799;
}
.wrapper .bank .info {
  margin-left: 20px;
  flex: 1;
  color: #000;
}
.wrapper .bank .info .row-content{
  margin: 30px 0;
  line-height: 20px;
  font-size: 30px;
}
.servicelistItem{
  display: flex;
  flex-direction: column;
  height: 800px;
  padding: 30px 30px;
  margin: 30px 20px;
  border-radius: 20px;
  justify-content: space-between;
  background: #fff;
}
.servicelistItem .servicelistItemTop{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 50px;
}
.servicelistItem .servicelistItemTop .servicelistItemImage{
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.servicelistItem .servicelistItemTop .servicelistItemText{
  margin-left: 120px;
  font-size: 43px;
  font-weight: 700;
  color: #000;
  flex: 1;
}
.servicelistItem .servicelistItemTop .servicelistItemBtn{
  display: flex;
  width: 150px;
  height: 55px;
  border-radius: 30px;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
      270deg,#e6c3a1,#7e5678);
}
.servicelistItem .servicelistItemTop .servicelistItemBtn .servicelistItemBtnText{
  color: #fff;
  font-size: 25px;
}
.servicelistItem .servicelistItemBottom{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  background: #f2f2f5;
  border-radius: 10px;
  color: #979799;
}
.servicelistItem .servicelistItemBottom .servicelistItemInfoText{
  font-size: 30px;
}
</style>
